import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { SessionCookies } from '../../data/enums';
import { AppRoutes } from '../../data/constants';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private cookieService: CookieService,
    private router: Router,
  ) {}

  canActivate(): boolean {
    return this.checkAuth();
  }

  private checkAuth(): boolean {
    if (this.cookieService.get(SessionCookies.accessToken)) {
      return true;
    } else {
      // Redirect to the login page if the user is not authenticated
      this.router.navigate([`/${AppRoutes.LOGIN}`]);
      return false;
    }
  }
}
