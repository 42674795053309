/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { SessionCookies } from '@enums';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root',
})
export class GoogleAnalyticsService {
  constructor(private cookieService: CookieService) {
    this.loadGoogleAnalytics();
  }

  private loadGoogleAnalytics() {
    const script = document.createElement('script');
    script.src = `https://www.googletagmanager.com/gtag/js?id=G-5WT4034FDF`;
    script.async = true;
    document.head.appendChild(script);

    const inlineScript = document.createElement('script');
    inlineScript.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-5WT4034FDF');
    `;
    document.head.appendChild(inlineScript);
  }

  public trackEvent(eventName: string, eventParams: any = {}) {
    const userName = this.cookieService.get(SessionCookies.userName);
    if (userName && eventName !== 'page_view' && eventName !== 'time_spent') {
      eventParams.user = userName;
    }
    (window as any).gtag('event', eventName, eventParams);
  }
}
